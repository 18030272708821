<template>
  <v-dialog
    v-model="dialog"
    max-width="60%"
    id="keyModal"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>{{modalData.id ? 'Edit ' + modalData.name : 'Create New Key Type'}} </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="modalData.id">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                id="keyName"
                label="Key Name"
                v-model="rowData.name"
                :rules="[rules.notEmpty]"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                id="keyId"
                label="Key ID"
                v-model="rowData.id"
                hint="Must be in all CAPS, no spaces and special characters '_'"
                :disabled="true"
                :presistent-hint="true"
                :rules="[rules.notEmpty]"
                required
                color="primary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                id="keyName"
                label="Key Name"
                v-model="keyData.name"
                :rules="[rules.notEmpty]"
                required
                color="primary"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                id="keyId"
                label="Key ID"
                v-model="upperCaseKeyDataName"
                hint="Must be in all CAPS, no spaces and special characters '_'"
                :presistent-hint="true"
                :rules="[rules.notEmpty]"
                required
                color="primary"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('close')">Close</v-btn>
        <v-btn
          id="saveKeyBtn"
          color="primary"
          text
          @click="$emit('saved', rowData.name?rowData:keyData)"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "keyvalueedit",
  props: {
      modalData: {
          type: Object
      }
  },
  data: () => ({
    rules: {
      notEmpty: (value) => !!value || "Required.",
    },
    keyData: {
      created_by: "",
      created_on: "",
      id: "",
      name: "",
      tenant: "",
      updated_by: "",
      updated_on: "",
    },
    rowData: {},
    dialog: false,
  }),
  mounted() {
    if (this.modalData.name) this.rowData = JSON.parse(JSON.stringify(this.modalData))
    this.dialog = true
  },
  computed: {
    upperCaseKeyDataName: {
      get() {
        return this.keyData.id
      },
      set(value) {
        this.keyData.id = value.toUpperCase().replace(/\s/g, "_")
      },
    },
  },
  watch: {
    "keyData.name": {
      deep: true,
      handler(newValue, oldValue) {
        if (!this.disableKeyId) {
          if (
            oldValue.toLowerCase() ===
            this.keyData.id.toLowerCase().replace(/_/g, " ")
          ) {
            this.keyData.id = newValue.toUpperCase().replace(/\s/g, "_")
          }
        }
      },
    },
  },
}
</script>
