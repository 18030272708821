<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card flat>
      <v-card-title style="padding-top: 20px">
        <v-icon left>mdi-scatter-plot-outline</v-icon> Party Attributes
        <v-select
          style="font-weight: normal; max-width: 300px; margin: 0 0 0 20px; padding: 0"
          :items="attributeTypes"
          item-text="name"
          item-value="id"
          label="Party Type"
          v-model="partyType"
          dense
          outlined
          hide-details>
        </v-select>
        <v-spacer></v-spacer>
        <v-btn id="newAttributeBtn" color="primary" dark absolute right @click="createNewAttribute()">
          Create New Attribute
        </v-btn>
      </v-card-title>
        <v-data-table
          id="keyListTable"
          :height="tableSize"
          v-model="selected"
          :headers="headers"
          :items="attributes"
          :loading="loading"
          :single-select="true"
          item-key="id"
          no-data-text='Please select a Party Type'
          fixed-header
          fixed-footer
          dense
          :footer-props="{'items-per-page-options': pageSizes}"
          :items-per-page.sync="pageSize"
          @click:row="selectRow"
          >
          <template v-slot:[`item.default_value`]="{ item }">
            <span v-if="item.data_type && item.data_type.constant === 'KEY_VALUE'">
              {{ item.default_key_value ? item.default_key_value.name : '' }}
            </span>
            <span v-else>
              {{ item.default_value || '' }}
            </span>
          </template>
        </v-data-table>
      </v-card>
  </v-container>
</template>
<script>
// API
import key from '@/axios/key-values-endpoint'
import attribute from '@/axios/party-attribute-endpoint'

// Mixins
import { fullwidth } from '@/mixins/fullwidth'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'party_attribute',
  data () {
    return {
      attributes: [],
      attributeTypes: [],
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Party Type', value: 'party_type.name' },
        { text: 'Data Type', value: 'data_type.name' },
        { text: 'Time Sensitive', value: 'is_time_sensitive' },
        { text: 'Required', value: 'is_required' },
        { text: 'Searchable', value: 'is_searchable' },
        { text: 'Multiple Values', value: 'allow_multiple' },
        { text: 'Valid Value', value: 'valid_value_key.name' },
        { text: 'Info', value: 'info' },
        { text: 'Display Order', value: 'display_order' },
        { text: 'Default Value', value: 'default_value' }
      ],
      loading: false,
      partyType: '',
      selected: [],
      pageSize: 50,
      pageSizes: [50, 100, 150]
    }
  },
  watch: {
    partyType: {
      deep: true,
      handler (newValue, oldValue) {
        this.initAttributeList()
      }
    }
  },
  mixins: [fullwidth, displayAlert],
  created () {
    this.nonTableHeight = 382
    let storePartyType = ''
    if (this.$route.meta.referrer === 'attribute') {
      storePartyType = (sessionStorage.getItem('ipro_party_type')) ? sessionStorage.getItem('ipro_party_type') : ''
    }
    if (storePartyType !== '') {
      this.partyType = sessionStorage.getItem('ipro_party_type')
    }
    this.initAttributeTypes()
  },
  methods: {
    createNewAttribute () {
      this.$router.push({ name: 'attribute' })
    },
    async initAttributeList () {
      this.loading = true
      await attribute.attribute.getList(this.partyType, 100, 0).then(response => {
        sessionStorage.setItem('ipro_party_type', this.partyType)
        this.attributes = response.data
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.handleError(err)
      })
    },
    async initAttributeTypes () {
      await key.keyValues.getList('PARTY_TYPE', 100, 0).then(response => {
        this.attributeTypes = response.data
      }).catch(err => {
        this.handleError(err)
      })
    },
    selectRow (attribute) {
      this.$router.push({ name: 'attribute', query: { id: attribute.id } })
    }
  }
}
</script>
