<template>
  <v-container fluid>
    <h2>Admin Dashboard</h2>
    <h5>With Great Power Comes Great Responsibilty</h5>
    <br>
    <v-card>
    <v-tabs color='primary' centered grow v-model="tab" :value="tab" >
      <v-tab v-for="item in tabs" :key="item.tab">
        <v-icon left >{{ item.icon }}</v-icon>
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model='tab'>
        <v-tab-item v-for="item in tabs" :key="item.tab">
          <component :is='item.content' v-model="item.data"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
// components
import keyValueComponent from '@/components/admin/key_value_edit'
import partyAttributeComponent from '@/components/admin/party_attribute_list'
import relationshipTypeComponent from '@/components/admin/relationship_type_list'

// mixins
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'admin',
  data () {
    return {
      tab: 'Config Types',
      tabs: [
        {
          tab: 'Config Types',
          content: 'keyValueComponent',
          icon: 'mdi-shape-outline',
          data: [],
          dataName: 'keyList'
        },
        {
          tab: 'Party Attributes',
          content: 'partyAttributeComponent',
          icon: 'mdi-scatter-plot-outline',
          data: []
        },
        {
          tab: 'Relationship Types',
          content: 'relationshipTypeComponent',
          icon: 'mdi-axis-arrow',
          data: []
        }
      ]
    }
  },
  components: {
    keyValueComponent,
    partyAttributeComponent,
    relationshipTypeComponent
  },
  mixins: [displayAlert],
  created () {
    if (this.$route.params.tab) {
      this.tab = this.tabs.findIndex((data) => {
        return data.tab === this.$route.params.tab
      })
    }
  }
}
</script>

<style>
.test {
  background: blue;
}
</style>
