var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-axis-arrow")]),
              _vm._v(" Relationship Types "),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "newRelationBtn",
                    color: "primary",
                    dark: "",
                    absolute: "",
                    right: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.createNewRelationshipType()
                    }
                  }
                },
                [_vm._v(" Create Relationship Type ")]
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              id: "RTListTable",
              height: _vm.tableSize,
              headers: _vm.headers,
              items: _vm.relationshipTypes,
              "single-select": true,
              "item-key": "id",
              "fixed-header": "",
              "fixed-footer": "",
              dense: "",
              "footer-props": { "items-per-page-options": _vm.pageSizes },
              "items-per-page": _vm.pageSize
            },
            on: {
              "update:itemsPerPage": function($event) {
                _vm.pageSize = $event
              },
              "update:items-per-page": function($event) {
                _vm.pageSize = $event
              },
              "click:row": _vm.selectRow
            },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }