var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h2", [_vm._v("Admin Dashboard")]),
      _c("h5", [_vm._v("With Great Power Comes Great Responsibilty")]),
      _c("br"),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                color: "primary",
                centered: "",
                grow: "",
                value: _vm.tab
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm._l(_vm.tabs, function(item) {
                return _c(
                  "v-tab",
                  { key: item.tab },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(_vm._s(item.icon))
                    ]),
                    _vm._v(" " + _vm._s(item.tab) + " ")
                  ],
                  1
                )
              }),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.tabs, function(item) {
                  return _c(
                    "v-tab-item",
                    { key: item.tab },
                    [
                      _c(item.content, {
                        tag: "component",
                        model: {
                          value: item.data,
                          callback: function($$v) {
                            _vm.$set(item, "data", $$v)
                          },
                          expression: "item.data"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }