var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-scatter-plot-outline")
              ]),
              _vm._v(" Party Attributes "),
              _c("v-select", {
                staticStyle: {
                  "font-weight": "normal",
                  "max-width": "300px",
                  margin: "0 0 0 20px",
                  padding: "0"
                },
                attrs: {
                  items: _vm.attributeTypes,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Party Type",
                  dense: "",
                  outlined: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.partyType,
                  callback: function($$v) {
                    _vm.partyType = $$v
                  },
                  expression: "partyType"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "newAttributeBtn",
                    color: "primary",
                    dark: "",
                    absolute: "",
                    right: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.createNewAttribute()
                    }
                  }
                },
                [_vm._v(" Create New Attribute ")]
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              id: "keyListTable",
              height: _vm.tableSize,
              headers: _vm.headers,
              items: _vm.attributes,
              loading: _vm.loading,
              "single-select": true,
              "item-key": "id",
              "no-data-text": "Please select a Party Type",
              "fixed-header": "",
              "fixed-footer": "",
              dense: "",
              "footer-props": { "items-per-page-options": _vm.pageSizes },
              "items-per-page": _vm.pageSize
            },
            on: {
              "update:itemsPerPage": function($event) {
                _vm.pageSize = $event
              },
              "update:items-per-page": function($event) {
                _vm.pageSize = $event
              },
              "click:row": _vm.selectRow
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.default_value",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.data_type && item.data_type.constant === "KEY_VALUE"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.default_key_value
                                    ? item.default_key_value.name
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        : _c("span", [
                            _vm._v(" " + _vm._s(item.default_value || "") + " ")
                          ])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }