<template>
  <v-container fluid class="ma-0 pa-0">
      <v-card flat>
        <v-card-title style="padding-top: 20px">
          <v-icon left>mdi-axis-arrow</v-icon> Relationship Types
          <v-btn id="newRelationBtn" color="primary" dark absolute right @click="createNewRelationshipType()">
            Create Relationship Type
          </v-btn>
        </v-card-title>
        <v-data-table
          id="RTListTable"
          :height="tableSize"
          v-model="selected"
          :headers="headers"
          :items="relationshipTypes"
          :single-select="true"
          item-key="id"
          fixed-header
          fixed-footer
          dense
          :footer-props="{'items-per-page-options': pageSizes}"
          :items-per-page.sync="pageSize"
          @click:row="selectRow"
          >
        </v-data-table>
      </v-card>
  </v-container>
</template>
<script>
import RelationshipType from '@/axios/party-relationship-type-endpoint'

import { fullwidth } from '@/mixins/fullwidth'
import { displayAlert } from '@/mixins/alert'

export default {
  name: 'relationship_type',
  data () {
    return {
      headers: [
        { text: 'From', align: 'start', sortable: true, value: 'from_rel.name' },
        { text: 'To', value: 'to_rel.name' },
        { text: 'Updated', value: 'updated_by' }
      ],
      relationshipTypes: [],
      selected: [],
      pageSize: 50,
      pageSizes: [50, 100, 150]
    }
  },
  mixins: [fullwidth, displayAlert],
  async created () {
    this.nonTableHeight = 374
    this.initRelationshipTypeList()
  },
  methods: {
    createNewRelationshipType () {
      this.$router.push({ name: 'relationship' })
    },
    async initRelationshipTypeList () {
      await RelationshipType.getList(100, 0).then(response => {
        this.relationshipTypes = response.data
      }).catch(err => {
        this.handleError(err)
      })
    },
    selectRow (relation) {
      this.$router.push({ name: 'relationship', query: { id: relation.id } })
    }
  }
}
</script>
