<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card flat>
      <v-card-title style="padding-top: 20px">
        <v-icon left>mdi-shape-outline</v-icon> Config Key Types
        <v-btn
          id="KeyModalBtn"
          color="primary"
          dark
          absolute
          right
          @click="openModal"
          >Create Key Type</v-btn
        >
      </v-card-title>
      <v-data-table
        id="keyListTable"
        :height="tableSize"
        v-model="selectedKey"
        :headers="keyTableHeaders"
        :items="keyList"
        :single-select="true"
        item-key="id"
        dense
        show-select
        fixed-header
        fixed-footer
        :footer-props="{ 'items-per-page-options': pageSizes }"
        :items-per-page.sync="pageSize"
        @click:row="selectRow"
      >
        <template v-slot:[`item.updated_on`]="{ item }">
          <span v-if="item.updated_on != null">{{
            moment(item.updated_on).format($config.timestamp)
          }}</span>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-btn icon @click="openModal(item)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <template v-if="selectedKey.length >= 1">
      <v-card flat>
        <v-card-title>
          <v-icon left>mdi-scatter-plot-outline</v-icon> Config Options for
          {{ selectedKey[0].name }}
          <v-btn
            id="KVModalBtn"
            color="primary"
            dark
            absolute
            right
            @click.stop="initKVModal"
          >
            New Config Option
          </v-btn>
        </v-card-title>
        <v-data-table
          :height="tableSize"
          :headers="KVTableHeaders"
          :items="KVList"
          :single-select="true"
          :loading="loadingKV"
          item-key="name"
          dense
          :footer-props="{ 'items-per-page-options': pageSizes }"
          :items-per-page.sync="pageSize"
          fixed-header
          fixed-footer
        >
          <template v-slot:[`item.updated_on`]="{ item }">
            <span v-if="item.updated_on != null">{{
              moment(item.updated_on).format($config.timestamp)
            }}</span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn text icon @click.stop="initKVModal(item)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </template>

    <v-dialog v-model="KVModal" max-width="800px">
      <v-card>
        <v-card-title>
            {{KVData.id ? 'Edit Config Option': 'Create Config Option'}}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="keyList"
                  item-value="id"
                  item-text="name"
                  v-model="KVData.key_id"
                  label="Config Key Type"
                  required
                  color="primary"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Display Name"
                  v-model="KVData.name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Key Constant"
                  v-model="KVConst"
                  hint="Must be in all CAPS, no spaces and special characters '_'"
                  :disabled="disableKVConst"
                  :presistent-hint="true"
                  required
                  color="primary"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Display Order"
                  type="number"
                  v-model="KVData.display_order"
                  required
                  color="primary"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="clearKVData">Close</v-btn>
          <v-btn color="primary" id="saveKvBtn" text @click="saveKV"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <KeyModal
      v-if="modalOpened"
      :modalData="modalData"
      @close="modalOpened = false"
      @saved="saveKey($event)"
    />
  </v-container>
</template>

<script>
import KeyModal from "@/components/admin/key_edit_modal"
import kvApi from "@/axios/key-values-endpoint.js"

import _ from "lodash"

import { fullwidth } from "@/mixins/fullwidth"
import { displayAlert } from "@/mixins/alert"

export default {
  name: "keyValueComponent",
  components: {
    KeyModal,
  },
  data() {
    return {
      modalOpened: false,
      modalData: {},
      keyTableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Updated On", value: "updated_on" },
        { text: "", value: "edit" },
      ],
      KVTableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Updated On", value: "updated_on" },
        { text: "", value: "edit" },
      ],
      keyData: {
        id: "",
        name: "",
        created_by: "hardcoded@email.com",
        updated_by: "hardcoded@email.com",
        created_on: "2020-03-30T03:29:56.518Z",
        updated_on: "2020-03-30T03:29:56.518Z",
      },
      KVData: {
        id: "",
        key_id: "",
        constant: "",
        name: "",
        display_order: 1,
        created_by: "hardcoded@email.com",
        updated_by: "hardcoded@email.com",
        created_on: "2020-03-30T03:29:13.819Z",
        updated_on: "2020-03-30T03:29:13.819Z",
      },
      keyList: [],
      KVList: [],
      loadingKV: false,
      KVModal: false,
      rules: {
        notEmpty: (value) => !!value || "Required.",
      },
      selectedKey: [],
      oldKeyId: "",
      selectedKV: [],
      showKVList: false,
      tab: "Types",
      tabs: [
        {
          tab: "Types",
          content: "keyComponent",
          icon: "mdi-shape-outline",
          data: [],
          dataName: "keyList",
        },
        {
          tab: "Options",
          content: "KVComponent",
          icon: "mdi-scatter-plot-outline",
          data: this.KVList,
        },
      ],
      pageSize: 50,
      pageSizes: [50, 100, 150],
    }
  },
  computed: {
    KVConst: {
      get() {
        return this.KVData.constant
      },
      set(value) {
        this.KVData.constant = value.toUpperCase().replace(/\s/g, "_")
      },
    },
    disableKeyId() {
      return this.selectedKey.length >= 1
    },
    disableKVConst() {
      return this.selectedKV.length >= 1
    },
  },
  watch: {
    "KVData.name": {
      deep: true,
      handler(newValue, oldValue) {
        if (!this.disableKVConst) {
          if ( oldValue.toLowerCase() === this.KVData.constant.toLowerCase().replace(/_/g, " ") || oldValue.toLowerCase() === this.KVData.constant.toLowerCase() ) {
            this.KVData.constant = newValue.toUpperCase().replace(/\s/g, "_")
          }
        }
      },
    },
    selectedKey: {
      deep: true,
      handler(newValue) {
        if (newValue.length >= 1) {
          this.initKVList(newValue[0].id)
        } else {
          this.KVList.length = 0
        }
      },
    },
  },
  mixins: [fullwidth, displayAlert],
  async created() {
    this.nonTableHeight = 374
    await this.initKeyList()
  },
  methods: {
    openModal(rowData) {
      if (rowData.name) this.modalData = rowData
      this.modalOpened = true
    },
    clearKeyData() {
      this.modalOpened = false
      this.modalData = {}
      Object.keys(this.keyData).forEach((key) => {
        this.keyData[key] = ""
      })
    },
    clearKVData() {
      Object.keys(this.KVData).forEach((key) => {
        if (["id", "key_id", "constant", "name"].indexOf(key) > -1) {
          if (this.KVData[key] !== "key_id") {
            this.KVData[key] = ""
          } else if (key === "display_order") {
            this.KVData.display_order = "0"
          }
        }
      })
      this.KVModal = false
    },
    async initKeyList() {
      try {
        this.keyList = (await kvApi.keys.getList(100, 0)).data
      } catch (err) {
        this.handleError(err)
      }
    },
    initKeyModal() {
      if (this.selectedKey[0]) {
        this.keyData = _.cloneDeep(this.selectedKey[0])
      }
    },
    async initKVList(keyId) {
      this.loadingKV = true
      try {
        this.KVList = (await kvApi.keyValues.getList(keyId, 100, 0)).data
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingKV = false
      }
    },
    initKVModal(rowData) {
      if (rowData.id) {
        this.KVData = _.cloneDeep(rowData)
      }
      this.KVData.key_id = _.cloneDeep(this.selectedKey[0])
      this.KVModal = true
    },
    async saveKey(updatedRowData) {
      this.keyData = _.cloneDeep(updatedRowData)
      if (!this.valid("key", ["name", "id"])) {
        return this.emitAlert(
          true,
          "warning",
          "All fields are required, please check they are all filled out!"
        )
      }
      try {
        if (this.keyData.created_by.length) {
          await kvApi.keys.put(this.keyData.id, this.keyData)
        } else {
          await kvApi.keys.post(this.keyData)
        }
        await this.initKeyList()
        this.clearKeyData()
      } catch (err) {
        this.handleError(err)
      }
    },
    async saveKV() {
      if (!this.valid("KV", ["key_id", "name", "constant"])) {
        return this.emitAlert(
          true,
          "warning",
          "All fields are required, please check they are all filled out!"
        )
      }
      const newData = _.cloneDeep(this.KVData)
      newData.key_id = _.cloneDeep(this.KVData.key_id.id)
      try {
        if (newData.id.length) {
          await kvApi.keyValues.put(newData.id, newData)
        } else {
          await kvApi.keyValues.post(newData)
        }
        this.clearKVData()
        await this.initKVList(newData.key_id)
      } catch (err) {
        this.handleError(err)
      }
    },
    selectRow(key, row) {
      if (row.isSelected === true) {
        row.isSelected = false
        row.select(false)
      } else {
        row.isSelected = true
        row.select(true)
      }
    },
    updatedKeyID(key) {
      if (key.toLowerCase() === this.keyData.id.toLowerCase()) {
        this.keyData.id = key
      }
    },
    valid(dataModel, fields) {
      let returnValue = true

      const dataName = dataModel + "Data"
      fields.forEach((field) => {
        if (this[dataName][field] === "") {
          returnValue = false
        }
      })
      return returnValue
    },
  },
}
</script>
