var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            { staticStyle: { "padding-top": "20px" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-shape-outline")
              ]),
              _vm._v(" Config Key Types "),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "KeyModalBtn",
                    color: "primary",
                    dark: "",
                    absolute: "",
                    right: ""
                  },
                  on: { click: _vm.openModal }
                },
                [_vm._v("Create Key Type")]
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              id: "keyListTable",
              height: _vm.tableSize,
              headers: _vm.keyTableHeaders,
              items: _vm.keyList,
              "single-select": true,
              "item-key": "id",
              dense: "",
              "show-select": "",
              "fixed-header": "",
              "fixed-footer": "",
              "footer-props": { "items-per-page-options": _vm.pageSizes },
              "items-per-page": _vm.pageSize
            },
            on: {
              "update:itemsPerPage": function($event) {
                _vm.pageSize = $event
              },
              "update:items-per-page": function($event) {
                _vm.pageSize = $event
              },
              "click:row": _vm.selectRow
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.updated_on",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.updated_on != null
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(item.updated_on)
                                  .format(_vm.$config.timestamp)
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.edit",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedKey,
              callback: function($$v) {
                _vm.selectedKey = $$v
              },
              expression: "selectedKey"
            }
          })
        ],
        1
      ),
      _vm.selectedKey.length >= 1
        ? [
            _c(
              "v-card",
              { attrs: { flat: "" } },
              [
                _c(
                  "v-card-title",
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-scatter-plot-outline")
                    ]),
                    _vm._v(
                      " Config Options for " +
                        _vm._s(_vm.selectedKey[0].name) +
                        " "
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          id: "KVModalBtn",
                          color: "primary",
                          dark: "",
                          absolute: "",
                          right: ""
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.initKVModal($event)
                          }
                        }
                      },
                      [_vm._v(" New Config Option ")]
                    )
                  ],
                  1
                ),
                _c("v-data-table", {
                  attrs: {
                    height: _vm.tableSize,
                    headers: _vm.KVTableHeaders,
                    items: _vm.KVList,
                    "single-select": true,
                    loading: _vm.loadingKV,
                    "item-key": "name",
                    dense: "",
                    "footer-props": { "items-per-page-options": _vm.pageSizes },
                    "items-per-page": _vm.pageSize,
                    "fixed-header": "",
                    "fixed-footer": ""
                  },
                  on: {
                    "update:itemsPerPage": function($event) {
                      _vm.pageSize = $event
                    },
                    "update:items-per-page": function($event) {
                      _vm.pageSize = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.updated_on",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.updated_on != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(item.updated_on)
                                        .format(_vm.$config.timestamp)
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.edit",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.initKVModal(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-pencil")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.KVModal,
            callback: function($$v) {
              _vm.KVModal = $$v
            },
            expression: "KVModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.KVData.id
                        ? "Edit Config Option"
                        : "Create Config Option"
                    ) +
                    " "
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.keyList,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Config Key Type",
                                  required: "",
                                  color: "primary"
                                },
                                model: {
                                  value: _vm.KVData.key_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.KVData, "key_id", $$v)
                                  },
                                  expression: "KVData.key_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Display Name", required: "" },
                                model: {
                                  value: _vm.KVData.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.KVData, "name", $$v)
                                  },
                                  expression: "KVData.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Key Constant",
                                  hint:
                                    "Must be in all CAPS, no spaces and special characters '_'",
                                  disabled: _vm.disableKVConst,
                                  "presistent-hint": true,
                                  required: "",
                                  color: "primary"
                                },
                                model: {
                                  value: _vm.KVConst,
                                  callback: function($$v) {
                                    _vm.KVConst = $$v
                                  },
                                  expression: "KVConst"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Display Order",
                                  type: "number",
                                  required: "",
                                  color: "primary"
                                },
                                model: {
                                  value: _vm.KVData.display_order,
                                  callback: function($$v) {
                                    _vm.$set(_vm.KVData, "display_order", $$v)
                                  },
                                  expression: "KVData.display_order"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.clearKVData }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "saveKvBtn", text: "" },
                      on: { click: _vm.saveKV }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.modalOpened
        ? _c("KeyModal", {
            attrs: { modalData: _vm.modalData },
            on: {
              close: function($event) {
                _vm.modalOpened = false
              },
              saved: function($event) {
                return _vm.saveKey($event)
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }